<template>
  <v-container>
    <div class="d-flex justify-end ">
      <v-chip class="ma-2" color="azul_med" label dark>
        <v-list-item-title>Total : {{ this.total }}</v-list-item-title>
      </v-chip>
      <v-chip class="ma-2" color="azul_med" label dark>
        <v-icon start>mdi-chevron-right-circle-outline</v-icon>
        <v-list-item-title>Creadas : {{ this.creadas }}</v-list-item-title>
      </v-chip>
      <v-chip class="ma-2" color="rojoalert" label dark>
        <v-icon start>mdi-alert-circle-outline</v-icon>
        <v-list-item-title>Cerradas : {{ this.cerradas }}</v-list-item-title>
      </v-chip>
    </div>
    <v-form>
      <v-row>
        <v-col cols="12" xs="8" sm="6" md="3" lg="2" xl="2">
          <v-menu v-model="menuStart" :close-on-content-click="false" transition="scale-transition" offset-y
            min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field outlined dense v-model="dateStart" label="Fecha de inicio" prepend-icon="mdi-calendar" readonly
                v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="dateStart" no-title scrollable :max="today"
              @input="menuStart = false"></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="12" xs="8" sm="6" md="3" lg="2" xl="2">
          <v-menu v-model="menuEnd" :close-on-content-click="false" transition="scale-transition" offset-y
            min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field outlined dense v-model="dateEnd" label="Fecha de fin" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="dateEnd" no-title scrollable :min="dateStart"
              @input="menuEnd = false"></v-date-picker>
          </v-menu>
        </v-col>
  
        <v-col cols="12" xs="8" sm="6" md="3" lg="3" xl="2">
          <v-text-field class="col" v-model="porIdRecibo" label="Id recibo" single-line hide-details
          outlined dense @change="comp_change()"></v-text-field>
        </v-col>
        <v-col class="mr-1">
          <v-btn color="azul_med" @click.native="Buscar" dark>Buscar</v-btn>
        </v-col>
      </v-row>
      <v-row justify="end">
        <v-col sm="12" md="4" lg="4" xl="4">
          <v-text-field class="col" v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details
            outlined dense @change="comp_change()"></v-text-field>
        </v-col>
      </v-row>
      <v-data-table :items-per-page="5" calculate-widths :headers="headers" :items="Result" item-key="IdCita"
        :search="search" :group-by="['Agente']" :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus',
            'items-per-page-text': 'Filas por página'
          }" no-data-text="No hay datos disponibles">
        <template v-slot:[`item.created_at`]="{ item }">
          <td> <span v-if="item.created_at"> {{ $formatDate(item.created_at) }}</span>
            <span v-else><v-icon dense color="red darken-2">
                mdi-close
              </v-icon></span>
          </td>
        </template>
        <template v-slot:[`item.Pagado`]="{ item }">
          <td v-if="item.Pagado > 0" class="text-end"><span class="estatus-activo" >{{ $formatCurrency(item.Pagado) }}</span></td>
          <td v-else class="text-end estatus-expirado">ABIERTA</td>
        </template>
      </v-data-table>
    </v-form>
  </v-container>
</template>

<script>
import ApiBase from '@/functions/axios/axiosNetBase'

export default {
  methods: {
    Buscar() {
      this.$loading(true)
      //var url = this.idcolectividad !== null ? `getMembresias?CodCole=${this.idcolectividad}` : `getMembresias`
      var url = `getCitasMedicas/${this.dateStart}/${this.dateEnd}`
      ApiBase(
        url,
        'GET'
      ).then((response) => {
        if (response.status === 200) {
          if (response.data) {
            this.Result = response.data
            this.creadas = this.Result.filter(item => item.created_at).length;
            this.cerradas = this.Result.filter(item => item.Pagado > 0).length;
            this.total = this.Result.length;
            this.$loading(false)
          }
        }
      })
        .catch((error) => {
          this.$loading(false)
          this.$swal({
            position: '',
            html: `Ha ocurrido un error inesperado membresías agente, <b>intenta mas tarde</b> . ${error}`,
            icon: 'error',
            confirmButtonText: 'Cerrar',
            showCloseButton: true,
            showConfirmButton: true,
          })
        });
    }
  },
  data() {
    return {
      porIdRecibo: null,
      total: 0,
      creadas: 0,
      cerradas: 0,
      Result: [],
      search: '',
      headers: [
        { text: 'IdCita', value: 'IdCita', sortable: false },
        { text: 'Tarjeta', value: 'IdTarjeta', sortable: false },
        //{ text: 'IdAgente', value: 'IdAgente', sortable: false },
        { text: 'Agente', value: 'Agente', sortable: false },
        { text: 'Parentesco', value: 'Parentesco', sortable: false },
        { text: 'Beneficiado', value: 'Beneficiado', sortable: false },
        { text: 'Especialidad', value: 'Especialidad', sortable: false },
        { text: 'Medico', value: 'Medico', sortable: false },
        { text: 'Colectividad', value: 'Colectividad', sortable: false },
        //{ text: 'ContEmail', value: 'ContEmail', sortable: false },
        //{ text: 'ContTel', value: 'ContTel', sortable: false },
        { text: 'Pagado', value: 'Pagado', sortable: false },
        { text: 'Creado', value: 'created_at', sortable: false },
        { text: 'Opciones', value: 'Membresia', sortable: false },
      ],
      dateStart: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      dateEnd: null,
      menuStart: false,
      menuEnd: false,
      today: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10), //new Date().toISOString().substr(0, 10) // Fecha de hoy en formato YYYY-MM-DD
    };
  },
};
</script>
