<template>
  <v-container fluid>
    <div class="text-h6 text-md-h4 text-lg-h4 mb-3 gris--text">Listado recibos</div>
    <v-row>
    <v-col xs="12" sm="12" md="8" lg="8" xl="8">
        <v-row no-gutters>
          <v-col xs="8" sm="6" md="3" lg="3" xl="2">
            <YearPicker v-model="selectedYear" />
          </v-col>
          <v-col xs="4" sm="4" md="2" lg="2" xl="2" class="text-center">
            <v-btn color="azul_med" @click.native="onClickFunction" dark elevation="0">Buscar</v-btn>
          </v-col>
          <v-col xs="4" sm="4" md="2" lg="2" xl="2" class="text-center">
            <v-btn color="azulfuerte" @click.native="verSoloPendientes" dark elevation="0">Ver pendientes</v-btn>
          </v-col>
        </v-row>
      </v-col>
    <v-col class="ms-auto">
        <v-row no-gutters>
          <v-col cols="4">
            <v-col class="text-sm-h6  azul_med--text">
              <v-list-item-subtitle>Recibos</v-list-item-subtitle>
              {{ this.num_recibos }}
            </v-col>
          </v-col>
          <v-col cols="4">
            <v-col class="text-sm-h6  red--text">
              <v-list-item-subtitle>Impago</v-list-item-subtitle>
              {{ this.impago }}
            </v-col>
          </v-col>
          <v-col cols="4">
            <v-col class="text-sm-h6 green--text">
              <v-list-item-subtitle>Pagados</v-list-item-subtitle>
              {{ this.pagados }}
            </v-col>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div class="cont-bord-gris">
      <v-row no-gutters justify="end">
        <!--<v-col cols="8">
           <v-row>
            <v-col sm="6" md="6" lg="3">
              <YearPicker v-model="selectedYear" />
            </v-col>
            <v-col sm="2" md="2" lg="2" class="text-center">
              <v-btn color="azul_med" @click.native="onClickFunction" dark elevation="0">Buscar</v-btn>
            </v-col>
          </v-row> 
        </v-col>-->
        <v-col xs="6" sm="6" md="4" lg="4" xl="3">
          <v-text-field class="col" v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details
            outlined dense @change="comp_change()"></v-text-field>
        </v-col>
      </v-row>
      <v-data-table :group-by="'agente'" :items-per-page="-1" fixed-header calculate-widths :headers="headers"
        :items="pagos" item-key="idpago" :search="search"  show-expand :expanded.sync="expanded"           
        class="elevation-1">
        <!-- <template v-slot:[`item.idcontrato`]="{ item }">
          <span class="grey--text">{{ item.idcontrato }}</span>
        </template> -->
        <template v-slot:[`item.recibos`]="{ item }">
          <span class="grey--text">{{ item.idpago }}</span>
        </template>
        <template v-slot:[`item.pagado`]="{ item }">
          <div v-if="item.pagado === 0">
            <span style="color: grey;">IMPAGO</span>
          </div>
          <div v-else>
            <strong>PAGADO</strong>
          </div>
        </template>
        <template v-slot:[`item.estado`]="{ item }">
          <span v-if="item.estado === '1'" class="estatus-activo">Activo</span>
          <span v-if="item.estado === '2'" class="estatus-expirado">Suspendida</span>
        </template>
        <template v-slot:[`item.recibo`]="{ item }">
          <span>{{ item.recibo }} de {{ item.recibos }}</span>
        </template>
        <template v-slot:[`item.DatePagoFormat`]="{ item }">
          <span class="creado-style">{{ item.DatePagoFormat }}</span>
        </template>
        <template v-slot:[`item.Diferencia`]="{ item }">
          <span v-if="item.Diferencia <= 15 && item.Diferencia > 0 && item.pagado == 0">{{ item.Diferencia }}
            días</span>
          <span v-if="item.Diferencia > 15 && item.pagado == 0" class="estatus-retardo">{{ item.Diferencia }}
            días</span>
          <span v-if="item.Diferencia < 0 && item.pagado == 0" style="color: olivedrab;">A tiempo</span>
        </template>
        <!-- <template v-slot:[`item.idpago`]="{ item }">
        <v-card-actions>
          <v-btn color="blue darken-1" text :href="`https://adm.medicover.mx/admin/instructivo.php?id=${item.idpago}`"
            target="_blank">VER RECiBO</v-btn> |
                        <v-btn class="black--text  text" text to="/carrito" icon>
              Pagar
          </v-btn> |
          <email-recibo-modal v-if="item.pagado=='0'" :ReciboId="item.idpago" :Membresia="item.idcontrato"
            :Recibo="`${item.recibo} de ${item.recibos}`" :Titular="item.nombretitular"
            :Monto="$formatCurrency(item.pago)"></email-recibo-modal>

        </v-card-actions>
      </template> -->
        <template v-slot:[`item.pago`]="{ item }">
          <span class="estatus-activo">{{ $formatCurrency(item.pago) }}</span>
        </template>
        <template v-slot:[`item.idpago`]="{ item }">
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn red icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <!-- <v-list-item @click="editItem(item)">
              <v-list-item-title>Pagar</v-list-item-title>
            </v-list-item>
            <v-list-item @click="deleteItem(item)">
              <v-list-item-title>Registrar pago</v-list-item-title>
            </v-list-item> -->
              <!-- <v-list-item @click="deleteItem(item)">
              <v-list-item-title>Ver pagos</v-list-item-title>
            </v-list-item> -->
              <v-list-item :disabled="true">
                <email-recibo-modal :ReciboId="item.idpago" :Membresia="item.idcontrato"
                  :Recibo="`${item.recibo} de ${item.recibos}`" :Titular="item.nombretitular"
                  :Monto="$formatCurrency(item.pago)"></email-recibo-modal>
              </v-list-item>
              <v-list-item :disabled="!(item.pagado == '0')" @click="verRecibo(item.idpago)">
                    Ver recibo
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:expanded-item="{ item }">
          
            <td :colspan="headers.length">
              <v-row no-gutters>
                <v-col cols="12">
                  <v-expand-transition>
                    <v-card flat>
                      <v-card-text>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>Nombre titular: {{ item.nombretitular }}</v-list-item-title>
                            <v-list-item-subtitle v-if="item.IdColectivo">Colectivo: {{ item.NomColectivo }}</v-list-item-subtitle>
                            <v-list-item-subtitle v-if="item.estado=='1'">Estatus: <strong>ACTIVO</strong></v-list-item-subtitle>
                            <v-list-item-subtitle v-else>Estatatus: <strong>SUSPENDIDO</strong></v-list-item-subtitle>
                            <v-list-item-subtitle>Número de pagos: {{ item.recibos }}</v-list-item-subtitle>
                            <v-list-item-subtitle>Renovación: {{ item.renovacion }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-card-text>
                    </v-card>
                  </v-expand-transition>
                </v-col>
              </v-row>
            </td>
        
        </template>
      </v-data-table>
    </div>

  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import ApiBase from '@/functions/axios/axiosNetBase'
import YearPicker from '@/components/AnioComponent.vue';
import EmailReciboModal from '@/components/EmailReciboComponent.vue'

export default {
  name: 'InicioAgente',
  components: {
    YearPicker,
    EmailReciboModal
  },
  computed: {
    ...mapGetters(['getCODAGE'])
  },
  mounted() {
    this.getPagosPendientesAgente()
  },
  data: () => ({
    selectedYear: new Date().toISOString().substr(0, 7), // new Date().getFullYear().toString(), or null
    search: '',
    pagos: [],
    expanded: [],
    headers: [
      //{ text: 'Num', value: 'num', width: '5%' },
      //{ text: 'Colectivo', value: 'IdColectivo' },
      //{ text: 'Colectivo', value: 'NomColectivo' },

      { text: 'Contrato', value: 'idcontrato'},
      { text: 'Agente', value: 'agente' },
      //{ text: 'Plan', value: 'Plan' },
      //{ text: 'Tipo pago', value: 'Fraccion' },
      { text: 'Titular', value: 'contratante'},
      //{ text: 'Titular', value: 'nombretitular' },
      { text: 'Fecha pago', value: 'DatePagoFormat'},
      { text: 'recibo', value: 'recibo', sortable: false },
      { text: 'Retardo', value: 'Diferencia' , sortable: false},
      { text: 'Estatus', value: 'pagado' },
      { text: 'Monto', value: 'pago', sortable: false },
      { text: 'Opciones', value: 'idpago', sortable: false },
      { text: 'Detalle', value: 'data-table-expand', sortable: false },
    ],
    num_recibos: 0,
    pagados:0,
    impago:0,
  }),
  methods: {
    verRecibo(idrecibo){
      //var idesecreto =  this.$encrypta(idrecibo)
      window.open(`https://desarrollo.bucketapis.com/api/ReciboMedicover/${idrecibo}`, '_blank'); // Abre en una nueva pestaña
    },
    editItem(item) {
      console.log(item)
    },
    deleteItem(item) {
      console.log(item)
    },
    onCleardate() {
      this.selectedYear = null
      this.getPagosPendientesAgente()
    },
    onClickFunction() {
      this.getPagosPendientesAgente()
    },
    verSoloPendientes(){
      this.selectedYear = null
      this.getPagosPendientesAgente()
    },
    getPagosPendientesAgente() {
      this.$loading(true)
      var url = this.selectedYear ? `getCobranza?qry=${this.selectedYear}&CodAge=${this.getCODAGE}` : `getCobranza?CodAge=${this.getCODAGE}`
      if(this.getCODAGE){
        ApiBase(
          url,
          'GET'
      ).then((response) => {
        if (response.status === 200) {
          if (response.data) {
            this.$loading(false)
            this.pagos = response.data
            this.num_recibos = this.pagos.length;
            this.impago = this.pagos.filter(i => i.pagado === 0).length
            this.pagados = this.pagos.filter(i => i.pagado === 1).length
          }
        }
      })
        .catch(() => {
          this.$loading(false)
          this.$swal({
            position: '',
            // title: 'Valida',
            //text: 'Se genero un error',
            html: `Ha ocurrido un error inesperado, <b>intenta mas tarde</b> .`,
            icon: 'error',
            confirmButtonText: 'Cerrar',
            showCloseButton: true,
            showConfirmButton: true,
            //timer: 1500,
          })
        });
    }
    }
  }
}
</script>
<style scoped>
.creado-style {
  font-weight: bold;
  color: #696969;
}
</style>