import axios from 'axios'

const config = {
  method: 'GET',
  baseURL: `https://conect.medicover.mx/api/`, // 'https://pay.medicover.com.mx/api/',
  maxRedirects: 200,
  withCredentials: false,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'GET, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin',
    'Content-Type': 'application/json'
  }
}

export default function apiPet (url, method = 'GET', datos) {
  config.method = method
  config.data = datos
  return axios(url, config)
}
