<template>
  <v-container>
      <h2 class="grey--text"> Médicos especialistas </h2>
      <v-subheader class="pa-0">Selecciona una especialidad para ver los médicos a los que puedes acudir.</v-subheader>
      <v-row>
        <v-col cols="12" md="5" lg="5" sm="12">
          <v-combobox  clearable dense hide-selected outlined v-model="select" :items="items" item-text="nombre"
        item-value="idespecialidad" label="Elige especialidad" persistent-hint return-object single-line width="200"
        @change="onChangeEsp"></v-combobox>
        </v-col>
      </v-row>
        <v-row>
        <v-col v-for="(doctor, index) in doctors" :key="index" cols="12" sm="2" md="4">
          <v-card class="text-center">
            <v-avatar size="100" class="mx-auto mt-3">
              <img src='https://medicover.mx/img/defaultdoctor.jpg' :alt="'avatar:' + doctor.idusuario" />
            </v-avatar>
            <v-card-title class="justify-center"> {{ doctor.apaterno }} {{ doctor.amaterno }} {{ doctor.nombre }}</v-card-title>
            <v-card-subtitle>
              <v-rating v-model="rating" length="5" color="amber" density="compact" half-increments
                size="small"></v-rating>
              <small>
                5.0 ({{ doctor.numconsultas }})
              </small></v-card-subtitle>

            <v-card-text>
              Tel: {{ doctor.tels }} <br />
              Consultorio: {{ doctor.consultas }} <br />
              {{ doctor.direccion }}
            </v-card-text>

            <v-card-actions class="d-flex justify-center">
              <v-btn color="primary" :href="'tel:' + doctor.tels" outlined>
                Llamar
              </v-btn>
              <v-btn :href="`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(doctor.direccion)}`"
                target="_blank" color="primary">
                Ver en Google Maps
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
  </v-container>
</template>
<script>
// import fetch from 'cross-fetch'
export default {
  async mounted() {
    await this.getUserList()
  },
  methods: {
    handleRowClick(item) {
      if (window.getSelection().toString().length === 0) {
        // console.log(JSON.parse(localStorage.getItem('permisos')))
        this.nombredoctor = item.nombre + ' ' + item.apaterno + ' ' + item.amaterno
        this.telefono = item.tels // Use router.push here
        this.$swal({
          // position: 'top-end',
          title: 'Doctor',
          // input: 'text',
          icon: 'phone',
          html:
            '<b>' + this.nombredoctor + '</b>,<br/>' +
            '<br/><a href="tel:' + this.telefono + '"><h2 style="color:blue">Llamar</h2></a><br/> ',
          confirmButtonText: 'Cerrar',
          showCloseButton: true,
          showConfirmButton: true,
          closeButtonText: 'cerrar',
          cancelButtonColor: '#d33',
          confirmButtonColor: '#3085d6'
          // timer: 1500,
        }).then((data) => {
          console.log(data)
        })
      }
    },
    llamando(telefono) {
      this.telefono = telefono // Use router.push here
      window.location.href = 'tel:' + telefono
      // window.open('tel:' + telefono)
    },
    onBusca(value) {
      // console.log(value)
      console.log(value)
    },
    async getUserList() {
      try {
        const response = await fetch(`https://conect.medicover.mx/api/especialidades`, {
          method: 'GET',
          headers: {
            accept: 'application/json'
          }
        })
        console.log(response)
        if (!response.ok) {
          throw new Error(`Error! status: ${response.status}`)
        }
        this.items = response.especialidades
        const result = await response.json()
        console.log(result.especialidades)
        this.items = result.especialidades
        return this.items
      } catch (err) {
        console.log(err)
        this.$loading(false)
        this.$swal({
          // position: 'top-end',
          title: 'Información',
          text: 'Hubo un error inesperado disculpa la molestia, por favor intentalo más tarde. ',
          // icon: 'error',
          confirmButtonText: 'Cerrar',
          showCloseButton: false
          // timer: 1500
        })
      }
    },
    onChangeEsp(event) {
      console.log(event.idespecialidad)
      this.idespecialidad = event.idespecialidad
      this.nombrePlan = event.nombre
      // this.btnpdf = false;
      this.getDoctors()
      // window.scrollTo({
      //   top: document.body.scrollHeight,
      //   behavior: 'smooth'
      // })
    },
    onChangePago(event) {
      this.pagos = event.num
      this.Calculos()
    },
    async onChangeCheck() {
      this.getDoctors()
    },
    async getDoctors() {
      this.$loading(true)
      if (this.idespecialidad !== '') {
        try {
          const response = await fetch(`https://conect.medicover.mx/api/doctores?idespecialidad=${this.idespecialidad}`, {
            method: 'POST',
            headers: {
              accept: 'application/json'
            },
            body: JSON.stringify({
              idespecialidad: this.idespecialidad
            })
          })
          console.log(response)
          if (!response.ok) {
            this.$loading(false)
            throw new Error(`Error! status: ${response.status}`)
          }
          this.doctors = response.medicos
          const result = await response.json()
          console.log(result.medicos)
          this.doctors = result.medicos
          this.$loading(false)
          return this.doctors
          
        } catch (err) {
          console.log(err)
          this.$loading(false)
          this.$swal({
            // position: 'top-end',
            title: 'Información',
            text: 'Hubo un error inesperado disculpa la molestia, por favor intentalo más tarde. ',
            // icon: 'error',
            confirmButtonText: 'Cerrar',
            showCloseButton: false
            // timer: 1500
          })
        }
      }
    }
  },
  data() {
    return {
      rating: 5,
      nombredoctor: '',
      telefono: '',
      ancho: window.innerWidth,
      largo: window.innerHeigth,
      name: 'Cotizador',
      title: 'Cotizador',
      select: '',
      // select: { nombre: 'Plan', consultas: '0' },
      pagodefault: { num: 1, nombre: 'ANUAL' },
      items: [],
      search: '',
      doctors: [],
      headersdoctors: [
        {
          text: 'Nombres',
          align: 'start',
          filterable: false,
          value: 'nombre'
        },
        { text: 'Apellido', value: 'apaterno' },
        { text: 'Apellido', value: 'amaterno' },
        { text: 'Correo', value: 'mail' },
        { text: 'Dirección', value: 'direccion' },
        { text: 'Teléfono', value: 'tels' }
      ],
      diferidos: [
        { num: 1, ab: 'ANUAL' },
        { num: 2, ab: 'SEMESTRAL' },
        { num: 4, ab: 'TRIMESTRAL' },
        { num: 12, ab: 'MENSUAL' }
      ],
      urgencias: true,
      consultas: true,
      dental: false,
      visual: false,
      subtotal: '$0.00',
      btnpdf: true,
      total: '$0.00',
      totalsinformato: 0,
      pagos: 1,
      financiamiento: '$0.00',
      nombrePlan: '',
      idespecialidad: '',
      primaneta: '$0.00',
      iva: '$0.00',
      exp: '$0.00',
      subsecuentes: '$0.00',
      primerpago: '$0.00',
      fecha: '',
      red: ''
    }
  }
}
</script>
<style lang="scss"></style>
