export default {
  /**
   * Rule validate required
   * @param {any} v the given value to validate
   * @param {string} label
   * @returns validate
   */
  ruleRequired: (v, label = null) => {
    return !!v || `El ${label ?? 'campo'} es requerido`;
  },
    /**
   * Rule validate min length
   * @param {any} v the given value to validate
   * @param {number} length min length to check
   * @param {string} label
   * @returns validate
   */
    ruleMinLengthCardPay: (v, length=19, label = null) => {
      return (
        String(v).trim().length === length ||
        `Los ${label ?? 'dígitos'} son ${length}`
      );
    },
  /**
   * Rule validate min length
   * @param {any} v the given value to validate
   * @param {number} length min length to check
   * @param {string} label
   * @returns validate
   */
  ruleMinLength: (v, length, label = null) => {
    return (
      String(v).length >= length ||
      `The ${label ?? 'field'} minimum characters is ${length}`
    );
  },
  /**
   * Rule validate max length
   * @param {any} v the given value to validate
   * @param {number} length max length to check
   * @param {string} label
   * @returns validate
   */
  ruleMaxLength: (v, length, label = null) => {
    return (
      String(v).length <= length ||
      `The ${label ?? 'field'} maximum characters is ${length}`
    );
  },

  /**
   * Rule validate phone number
   * @param {any} v the given value to validate
   * @param {string} label
   * @returns validate
   */
  ruleTelephone: (v, label = null) => {
    return (
      !v ||
      /^([0-9\\-])*$/.test(v) || `El ${label ?? 'télefono'} no tiene el formato correcto`
    );
  },
  ruleCp: (v, label = null) => {
    return (
      !v ||
      /^([0-9])*$/.test(v) || `El ${label ?? 'CP'} no tiene el formato correcto`
    );
  },
  /**
   * Rule validate decimal
   * @param {any} v the given value to validate
   * @param {string} label
   * @param {number} decimalPlace the decimal place to check
   * @param {boolean} checkOverDecimalOnly to check only over decimal place only
   * @returns validate
   */
  ruleDecimal: ({
    v,
    label = null,
    decimalPlace = 2,
    checkOverDecimalOnly = true,
  }) => {
    const regex = new RegExp(
      `^(0|[1-9]\\d*)((\\.)${checkOverDecimalOnly ? '?' : ''}\\d{${
        checkOverDecimalOnly ? '0,' : ''
      }${decimalPlace}})$`
    );
    if (v && !v.toString().replace(/\s/g, '').match(regex)) {
      return `The ${
        label ?? 'field'
      } must be a valid decimal with ${decimalPlace} fraction`;
    }
    return true;
  },
  /**
   * Rule validate number less than
   * @param {any} v the given value to validate
   * @param {any} targetValue the target value to check againt
   * @param {string} label
   * @returns validate
   */
  ruleLessThan: (v, targetValue, label = null) => {
    return (
      !v ||
      !targetValue ||
      parseFloat(v) < parseFloat(targetValue) ||
      `The ${label ?? 'field'} must less than ${targetValue}`
    );
  },
  /**
   * Rule validate number greater than
   * @param {any} v the given value to validate
   * @param {any} targetValue the target value to check againt
   * @param {string} label
   * @returns validate
   */
  ruleGreaterThan: (v, targetValue, label = null) => {
    return (
      !v ||
      !targetValue ||
      parseFloat(v) > parseFloat(targetValue) ||
      `The ${label ?? 'field'} must greater than ${targetValue}`
    );
  },
  /**
   * Rule validate integer number
   * @param {any} v the given value to validate
   * @param {string} label
   * @returns validate
   */
  ruleNumber: (v, label = null) => {
    return (
      Number.isInteger(Number(v)) ||
      `The ${label ?? 'field'} must be a valid integer`
    );
  },
  /**
   * Rule validate date before date
   * @param {any} v the given value to validate
   * @param {any} targetValue the target value to check againt
   * @param {string} label
   * @returns validate
   */
  ruleBeforeDate: (v, targetValue, label = null) => {
    return (
      !v ||
      !targetValue ||
      v < targetValue ||
      `The ${label ?? 'field'} must before ${targetValue}`
    );
  },
  /**
   * Rule validate date after date
   * @param {any} v the given value to validate
   * @param {any} targetValue the target value to check againt
   * @param {string} label
   * @returns validate
   */
  ruleAfterDate: (v, targetValue, label = null) => {
    return (
      !v ||
      !targetValue ||
      v > targetValue ||
      `The ${label ?? 'field'} must after ${targetValue}`
    );
  },
  /**
   * Rule validate is
   * @param {any} v the given value to validate
   * @param {any} targetValue the target value to check againt
   * @param {string} label
   * @returns validate
   */
  ruleIs: (v, targetValue, label = null) => {
    return (
      !v ||
      !targetValue ||
      v === targetValue ||
      `The ${label ?? 'field'} must be ${targetValue}`
    );
  },
    /**
   * Rule validate email
   * @param {any} v the given value to validate
   * @param {string} label
   * @returns validate
   */
    ruleEmail: (v, label = null) => {
      return (
        !v ||
        /^[a-zA-Z0-9-_.]+@[a-zA-Z0-9-_.]+(\.\w{2,3})+$/.test(String(v)) ||
        `El ${label ?? 'correo'} no tiene el formato correcto`
      );
    },
    /**
     * Rule validate is
     * @param {any} v the given value to validate
     * @param {string} label
     * @returns validate
     */
    ruleRfc: (v, label = null) => {
      return (
        !v ||
        /^([A-ZÑ&]{3,4})\d{6}([A-Z0-9]{3})?$/.test(v) || `Ingresa ${label ?? 'RFC'} válido`
      );
    },
    ruleRef4dig: (v, label = null) => {
      return (
        !v || 
        /^([0-9\\-])*$/.test(v) || `Ingresa ${label ?? 'Detalle'} válido`
      );
    },
      /**
     * Rule validate is
     * @param {any} v the given value to validate
     * @param {string} label
     * @returns validate
     */
    ruleCurp: (v,  label = null) => {
      return (
        !v ||
        /^([A-Z]{4}\d{6}[HM]{1}[A-Z]{5}[A-Z0-9]{2})?$/.test(v) || `Ingresa ${label ?? 'CURP'} válido`
      );
    },
    ruleNombre: (v,  label = null) => {
      return (
        !v ||
        /^[a-zA-Z\s]*$/.test(v) ||  `Ingrese un ${label ?? 'dato'} válido`
        // /^([A-Z]{4}\d{6}[HM]{1}[A-Z]{5}[A-Z0-9]{2})?$/.test(v) || `Ingresa ${label ?? 'filed'} válido`
      );
    },
      /**
     * Rule validate is
     * @param {any} v the given value to validate
     * @returns validate
     */
    ruleSelectReq : (v) => {
      return (
       // Boolean(Object.keys(v || {})[0]) || "Selecciona un elemento" 
         !! v.id > 0 || 'La seleccion es requerida.'
      )
    },
    ruleSelectDetallepago : (v) => {
      return (
       // Boolean(Object.keys(v || {})[0]) || "Selecciona un elemento" 
         !! v.CodPago > 0 || 'La seleccion es requerida.'
      )
    }
};