<template>
  <v-container fluid id="secimprimir">
    <h1 class="grey--text">Reporte Siniestralidad </h1>
    <v-row no-gutters>
      <v-col order="last">
        <v-sheet class="pa-2 ma-2">
          <!-- <v-btn color="success" elevation="0" large @click="Imprimir()">Imprimir</v-btn> -->
        </v-sheet>
      </v-col>
      <v-col>
        <v-sheet class="pa-2 ma-2">
          <v-select v-model="select" :items="items" item-text="anio"  item-value="anio"
            label="Select" persistent-hint return-object single-line outlined ></v-select>
        </v-sheet>
      </v-col>
      <v-col order="first">
        <v-sheet class="pa-2 ma-2">
          <v-autocomplete outlined v-model="$route.params.id" :rules="[v => !!v || 'Nombre requerido']"
            :item-text="item => item.NomColectividad" :item-value="'Id'" :items="colectividades" 
            @change="onChangeMembresia">
            <template v-slot:append-outer>
            </template>
          </v-autocomplete>
        </v-sheet>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col order="last">

        <v-row no-gutters>
          <v-col order="last">
            <v-sheet class="pa-2 ma-2">

            </v-sheet>
          </v-col>
          <v-col>
            <v-sheet class="pa-2 ma-2">
              <h3 class="grey--text"> Pendientes pago: {{ this.NumConsNopagadas }}</h3>
            </v-sheet>
          </v-col>
          <v-col order="first">
            <v-sheet class="pa-2 ma-2">
              <h3 class="grey--text"> Consultas: {{ $formatCurrency(this.totalconsultas)}}</h3>
            </v-sheet>
          </v-col>
        </v-row>
        <v-sheet class="pa-1 ma-1">
          <v-data-table fixed-header calculate-widths :headers="headersConsultas" :items="listConsultas"
            item-key="IdCita" disable-pagination>
            <template v-slot:[`item.total`]="{ item }">
              <th v-if="item.total == 0">Sin pagar</th>
              <th v-else>{{ $formatCurrency(item.total)}}</th>
            </template>
          </v-data-table>
        </v-sheet>
      </v-col>
      <v-col order="first">
        <v-row no-gutters>
          <v-col order="last">
            <v-sheet class="pa-2 ma-2">
              <h3 class="red--text"> Accidente: {{ this.NumAccidente }}</h3>
            </v-sheet>
          </v-col>
          <v-col>
            <v-sheet class="pa-2 ma-2">
              <h3 class="orange--text"> Enfermedad: {{ this.NumEnfermedad }}</h3>
            </v-sheet>
          </v-col>
          <v-col order="first">
            <v-sheet class="pa-2 ma-2">
              <h3 class="grey--text"> Urgencias {{ $formatCurrency(this.totalurgencias)}}</h3>
            </v-sheet>
          </v-col>
        </v-row>
        <v-sheet class="pa-1 ma-1">
          <v-data-table fixed-header calculate-widths :headers="headersUrgencias" :items="listUrgencias"
            item-key="IdUrgencia" disable-pagination>
            <template v-slot:[`item.TipoUrgencia`]="{ item }">
              <th v-if="item.TipoUrgencia==1">Urgencia</th>
              <th v-else>Enfermedad</th>
            </template>
            <template v-slot:[`item.fnac`]="{ item }">
              <th>{{ new Date(item.fnac).toLocaleString() }}</th>
            </template>
            <template v-slot:[`item.total`]="{ item }">
              <v-chip :color="item.total > 1000 ? 'red' : 'orange'" dark>
                {{ $formatCurrency(item.total) }}
              </v-chip>
            </template>
          </v-data-table>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import apiPet from '@/functions/axios/axios'
export default {
  name: 'versiniestralidad',
  data: () => ({
    totalurgencias: 0.00,
    totalconsultas: 0.00,
    NumConsNopagadas: 0,
    NumEnfermedad: 0,
    NumAccidente: 0,
    colectividades: [
      { Id: '' },
      { NomColectividad: '' }
    ],
    select: { anio: '2024' },
    items: [
      { anio: '2024' },
      { anio: '2023' },
      { anio: '2022' },
      { anio: '2021' },
      { anio: '2020' },
      { anio: '2019' },
      { anio: '2018' },
      { anio: '2017' },
      { anio: '2016' },
    ],
    headersUrgencias: [
      { text: 'Tipo', value: 'TipoUrgencia' },
      { text: 'Factura ', value: 'Factura' },
      { text: 'Membresía ', value: 'membresia' },
      { text: 'Fecha ', value: 'Fecha' },
      { text: 'Parentesco', value: 'Parentesco' },
      { text: 'Nombres', value: 'Nombres' },
      { text: 'Apellido', value: 'Apaterno' },
      { text: 'Diagnostico', value: 'Diagnostico' },
      { text: 'Total', value: 'total' },
    ],
    headersConsultas: [
      //{ text: 'ID ', value: 'IdCita' },
      { text: 'Creacion ', value: 'creacion' },
      { text: 'Membresía ', value: 'membresia' },
      { text: 'Nombres', value: 'Nombres' },
      { text: 'Especialidad', value: 'Espec' },
      { text: 'Parentesco', value: 'Parentesco' },
      { text: 'Pagado', value: 'pagado' },
      { text: 'Total', value: 'total' },
    ],
    recibospendientes: [],
    listUrgencias: [],
    listConsultas: [],
    comision: 0
  }),
  async mounted() {
    await this.getColectividades()
  },
  methods: {
    Imprimir() {
      console.log("imprimiendo")
    },
    async getSiniestralidad(idcol) {
      this.$loading(true)
      try {
        const response = await apiPet(
          'siniestralidadCobertura',
          'POST',
          {
            Id: idcol
          }
        )
        if (response.status === 200) {
          console.log(response.data)
          this.listUrgencias = response.data.urgencias
          this.listConsultas = response.data.consultas
          this.listUrgencias.forEach(d => {
            this.totalurgencias += d.total
            if (d.TipoUrgencia === 1) {
              this.NumAccidente += 1;
            } else {
              this.NumEnfermedad += 1;
            }

          })

          this.listConsultas.forEach(d => {
            this.totalconsultas += d.total
            if (d.total === 0) {
              this.NumConsNopagadas += 1;
            }
          })
          //this.colectividades = response.data.colectividades
          this.$loading(false)
        } else {
          console.log(response);
          this.$swal({
            // position: 'top-end',
            title: 'Error',
            text: 'Se genero un error',
            icon: 'error',
            confirmButtonText: 'Cerrar',
            showCloseButton: false
            // timer: 1500,
          })
        }
      } catch (error) {
        this.$loading(false)
      }
    },
    onChangeMembresia(val) {
      console.log(val)
      this.getSiniestralidad(val)
    },
    async getColectividades() {
      this.$loading(true)
      try {
        const response = await apiPet(
          'getColectividades',
          'GET'
        )
        if (response.status === 200) {
          console.log(response.data)
          this.colectividades = response.data.colectividades
          this.$loading(false)
          this.getSiniestralidad(this.$route.params.id)
        } else {
          console.log(response);
          this.$swal({
            // position: 'top-end',
            title: 'Error',
            text: 'Se genero un error',
            icon: 'error',
            confirmButtonText: 'Cerrar',
            showCloseButton: false
            // timer: 1500,
          })
        }
      } catch (error) {
        this.$loading(false)
      }
  
    },
    comp_change() {
      this.DB_DATA = this.db.filter(
        (x) =>
          !this.search.length ||
          x.nombres.toLowerCase().includes(this.search.toLowerCase()) ||
          x.idmembresia.toLowerCase().includes(this.search.toLowerCase())
      )
    }
  },
  components: {
    //ChartEstadistica
  },

}

</script>
  
  